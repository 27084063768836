import React, { useState, useEffect } from "react"
import PropTypes from "prop-types";
import {
  mediaPropTypes,
  linkPropTypes,
  buttonLinkPropTypes,
} from "@/utils/types";
import { useLockBodyScroll } from "@/utils/hooks";
import LocaleSwitch from "../locale-switch.js"
import CustomLink from "./custom-link";

const MobileNavMenu = ({ navbar, pageContext }) => {
  // Prevent window scroll while mobile nav menu is open
  useLockBodyScroll();

  const mapLinkToComponent = (link) => {
    // Internal link - use gatsby link
    if (link.url.startsWith('/')) {
      return <CustomLink
        link={{ ...link }} >
        <div className="hover:text-gray-900 py-5 flex flex-row justify-center items-center">
          <span>{link.text}</span>
        </div>
      </CustomLink>
    }
    // External link
    else {
      return <CustomLink link={{ ...link }}>
        <div className="hover:text-gray-900 py-5 flex flex-row justify-center items-center">
          <span>{link.text}</span>
        </div>
      </CustomLink>
    }
  }

  // Per comps, login link must be at the bottom of the page
  let loginLink = null;
  const rightLinks = [...navbar.rightlinks];
  loginLink = (rightLinks.splice(rightLinks.findIndex((link) => link.url.includes('https://rca.causelink.com')), 1)[0]);

  return (
    <div className="w-screen bg-primary left-0 overflow-y-hidden bg-white z-10 relative t-12 flex-grow">
      <div className="h-full flex flex-col justify-between pb-6">
        <div className="flex flex-col justify-end w-full mx-auto">
          <ul className="bg-white flex flex-col list-none items-baseline text-xl mb-10">
            {[...navbar.leftlinks, ...rightLinks].map((navLink) => (
              <li key={navLink.id} className="text-center block w-full border-t border-light-gray">{mapLinkToComponent(navLink)}</li>
            ))}
            <div className='w-full h-20 bg-gray'></div>
            {loginLink &&
              <li key={loginLink.id} className="text-center block w-full border-t border-light-gray">{mapLinkToComponent(loginLink)}</li>
            }
            <li key={navbar.button.id} className="text-center block w-full border-t border-b border-light-gray">{mapLinkToComponent(navbar.button)}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

MobileNavMenu.propTypes = {
  navbar: PropTypes.shape({
    logo: mediaPropTypes,
    leftlinks: PropTypes.arrayOf(linkPropTypes),
    rightlinks: PropTypes.arrayOf(linkPropTypes),
    button: buttonLinkPropTypes,
  }),
};

export default MobileNavMenu;
