import React from "react"
import classNames from "classnames";
import PropTypes from "prop-types";
import { buttonLinkPropTypes } from "@/utils/types";
import CustomLink from "./custom-link";

export const ButtonContent = ({ button, appearance, compact, onClickCapture }) => {
  return (
    <button
      onClickCapture={onClickCapture}
      className={classNames(
        // Common classes
        "block w-full lg:w-auto rounded-full text-center tracking-wide border-2 transition-colors text-lg disabled:opacity-75 disabled:cursor-not-allowed",
        // Full-size button
        {
          "px-14 py-2 text-xl": compact === false,
        },
        // Compact button
        {
          "px-4 py-1 font-thin": compact === true,
        },
        // Specific to when the button is fully primary
        {
          "bg-primary disabled:bg-primary text-white border-primary disabled:border-primary hover:bg-primary-buttonHovered hover:border-primary-buttonHovered": appearance === "dark",
        },
        // Specific to when the button is fully dark
        {
          "bg-secondary disabled:bg-secondary text-white border-secondary disabled:border-secondary hover:bg-secondary-buttonHovered hover:border-secondary-buttonHovered": appearance === "dark-secondary",
        },
        // Specific to when the button is dark outlines
        {
          "bg-primary text-white border-primary": appearance === "dark",
        },
        // Specific to when the button is fully white
        {
          "bg-white text-primary border-white": appearance === "white",
        },
        // Specific to when the button is white outlines
        {
          "text-white border-white hover:text-white hover:bg-primary": appearance === "white-outline",
        },
        // Ghost
        {
          'bg-transparent hover:bg-primary border-primary hover:text-white': appearance === 'ghost',
        },
        // Ghost outline
        {
          'bg-transparent border-white hover:bg-white hover:text-primary': appearance === 'ghost-outline',
        },
      )}
    >
      {button.text}
    </button>
  );
};

const ButtonLink = ({ button, appearance, compact = false, className = '', linkClassName = '', tabIndex = 0, onClickCapture }) => {
  return (
    <CustomLink className={classNames(linkClassName, 'hover:no-underline')} link={button}>
      <ButtonContent button={button} appearance={appearance} compact={compact} className={className} tabIndex={tabIndex} onClickCapture={onClickCapture} />
    </CustomLink>
  );
};

ButtonLink.propTypes = {
  button: buttonLinkPropTypes,
  appearance: PropTypes.oneOf([
    "dark",
    "white-outline",
    "white",
    "dark-outline",
    'ghost',
    'dark-secondary',
  ]),
  compact: PropTypes.bool,
};

export default ButtonLink;
