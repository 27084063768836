import { Cookies } from 'react-cookie';
export function getStrapiURL(path) {
  return `${process.env.GATSBY_STRAPI_URL || 'http://localhost:1337'
    }${path}`
}

// UTM Parameters
export const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];

// Helper to make GET requests to Strapi

export async function fetchAPI(path, options = {}) {
  const defaultOptions = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  const mergedOptions = {
    ...defaultOptions,
    ...options,
  }
  const requestUrl = getStrapiURL(path)
  const response = await fetch(requestUrl, mergedOptions)

  if (!response.ok) {
    console.error(response.statusText)
    throw new Error(`An error occured please try again`)
  }
  const data = await response.json()
  return data
}

/**
 * Helper function for PUTting data as JSON with fetch.
 * Courtesy of https://simonplend.com/how-to-use-fetch-to-post-form-data-as-json-to-your-api/
 *
 * @param {Object} options
 * @param {string} options.url - URL to PUT data to
 * @param {FormData} options.formData - `FormData` instance
 * @return {Object} - Response body from URL that was POSTed to
 */
export async function postFormDataAsJson({ url, formData }) {
  const plainFormData = Object.fromEntries(formData.entries());
  const formDataJsonString = JSON.stringify(plainFormData).replaceAll(/\"(true)\"/g, true);

  const cookies = new Cookies();

  const utmHeaders = {};
  utmParams.forEach(utmParam => {
    if (cookies.get(utmParam)) {
      utmHeaders[`X-${utmParam}`] = cookies.get(utmParam);
    }
  });

  const fetchOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...utmHeaders,
    },
    body: formDataJsonString,
  };

  const response = await fetch(url, fetchOptions);

  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(errorMessage);
  }

  return true;
}