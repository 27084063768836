import React from "react"
import Image from "../image"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { linkPropTypes, mediaPropTypes } from "@/utils/types"
import { localizePath } from "@/utils/localize"
import { FaInstagramSquare, FaFacebookSquare, FaTwitterSquare, FaLinkedin } from "react-icons/fa"
import CustomLink from "./custom-link"
// import LocaleSwitch from "../locale-switch.js"

const Footer = ({ footer, pageContext }) => {
  const mapLinkToComponent = (link) => {
    // Internal link - localize and use gatsby link
    if (link.url.startsWith('/')) {
      return <CustomLink
        link={{ ...link }} >
        <div className="hover:text-primary px-2 py-1">
          {link.text}
        </div>
      </CustomLink>
    }
    // External link
    else {
      return <CustomLink link={{ ...link }}>
        <div className="hover:text-primary px-2 py-1">
          {link.text}
        </div>
      </CustomLink>
    }
  }

  return (
    <footer className="flex flex-col justify-center lg:justify-start pt-8 lg:pt-12 border-primary border-b-4 bg-gray-darkest" >
      <div className="container text-center lg:text-left inline lg:flex flex-col lg:justify-start">
        <div className='flex lg:inline-block justify-center'>
          <Link to={localizePath({ ...pageContext, slug: "" })}>
            {footer.logo && (
              <Image media={footer.logo} className="h-auto w-44 lg:w-60 object-contain" />
            )}
          </Link>
        </div>
        <nav className="flex flex-wrap flex-row justify-start mt-10 mb-10 text-white">
          <ul className='flex flex-row flex-wrap justify-center lg:justify-start items-center list-none gap-4 lg:gap-12'>
            {footer.urls.map(link => (
              <li key={link.id}>
                {mapLinkToComponent(link)}
              </li>
            ))}
          </ul>
          {/* Locale Switch Desktop */}
          {/* {pageContext.localizations && (
            <div className="text-black">
              <LocaleSwitch footer={true} pageContext={pageContext} />
            </div>
          )} */}
        </nav>
      </div>

      <div className="text-sm text-center lg:text-left py-6 text-gray-400">
        <div className="container">{footer.smallText1}</div>
        <div className="container">{footer.smallText2}</div>
        <div className="container">{footer.smallText3}</div>
      </div>

      <div className='container flex flex-col lg:flex-row items-center lg:items-start justify-between gap-8 lg:gap-0 text-lg text-gray-400 mt-4 lg:mt-0 mb-12'>
        <div class="AuthorizeNetSeal">
          <script type="text/javascript" language="javascript">var ANS_customer_id="bc72ea88-d00b-4a5f-9590-787876fc71c8";</script>
          <script type="text/javascript" language="javascript" src="//verify.authorize.net/anetseal/seal.js"></script>
          {/* <style type="text/css">
            {`
            div.AuthorizeNetSeal{text - align:center;margin:0;padding:0;width:90px;font:normal 9px arial,helvetica,san-serif;line-height:10px;}
            div.AuthorizeNetSeal a{text - decoration:none;color:black;}
            div.AuthorizeNetSeal a:visited{color:black;}
            div.AuthorizeNetSeal a:active{color:black;}
            div.AuthorizeNetSeal a:hover{text - decoration:underline;color:black;}
            div.AuthorizeNetSeal a img{border:0px;margin:0px;text-decoration:none;}
            `}
          </style> */}
          <a href="https://verify.authorize.net/anetseal/?pid=bc72ea88-d00b-4a5f-9590-787876fc71c8&amp;rurl=https://www.causelink.com" onmouseover="window.status='http://www.authorize.net/'; return true;" onmouseout="window.status=''; return true;" onclick="window.open('https://verify.authorize.net/anetseal/?pid=bc72ea88-d00b-4a5f-9590-787876fc71c8&amp;rurl=https://www.causelink.com','AuthorizeNetVerification','width=600,height=430,dependent=yes,resizable=yes,scrollbars=yes,menubar=no,toolbar=no,status=no,directories=no,location=yes'); return false;" rel="noopener noreferrer" target="_blank">
            <img src="https://verify.authorize.net/anetseal/images/secure90x72.gif" className='h-auto w-20' border="0" alt="Authorize.Net Merchant - Click to Verify" />
          </a>

          {/* <a href="http://www.authorize.net/" id="AuthorizeNetText" target="_blank">Accept Credit Cards</a> */}
        </div>
        <div className='flex flex-row gap-8 lg:gap-2'>
          {footer.media.instagram &&
            <a href={footer.media.instagram} target="_blank">
              <FaInstagramSquare className='h-6 w-auto hover:text-white' />
            </a>
          }
          {footer.media.facebook &&
            <a href={footer.media.facebook} target="_blank">
              <FaFacebookSquare className='h-6 w-auto hover:text-white' />
            </a>
          }
          {footer.media.twitter &&
            <a href={footer.media.twitter} target="_blank">
              <FaTwitterSquare className='h-6 w-auto hover:text-white' />
            </a>
          }
          {footer.media.linkedIn &&
            <a href={footer.media.linkedIn} target="_blank">
              <FaLinkedin className='h-6 w-auto hover:text-white' />
            </a>
          }
        </div>
      </div>

    </footer >
  )
}

Footer.propTypes = {
  footer: PropTypes.shape({
    logo: mediaPropTypes.isRequired,
    urls: PropTypes.arrayOf(linkPropTypes),
    smallText1: PropTypes.string.isRequired,
    smallText2: PropTypes.string.isRequired,
    smallText3: PropTypes.string.isRequired,
  }),
}

export default Footer
