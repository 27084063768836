import React, { useEffect, useLayoutEffect, useRef } from "react"
import { useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { MdClose, MdMenu } from "react-icons/md"
import MobileNavMenu from "./mobile-nav-menu"
import ButtonLink from "./button-link"
import Image from "../image"
import {
  mediaPropTypes,
  linkPropTypes,
  buttonLinkPropTypes,
} from "@/utils/types"
import { getButtonAppearance } from "@/utils/button"
import CustomLink from "./custom-link"
import LocaleSwitch from "../locale-switch.js"
import { localizePath, getLocalizationKey } from "@/utils/localize"
import classNames from "classnames"
import { useCookies } from "react-cookie"
import { utmParams } from "../../utils/api"

const Navbar = ({ navbar, pageContext }) => {
  const [mobileMenuIsShown, setMobileMenuIsShown] = useState(false)
  const [stuck, setStuck] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies();
  const [locale] = useState(
    cookies.GATSBY_LOCALE || pageContext.locale
  )
  const getLocalization = getLocalizationKey.bind(null, pageContext.localizationKeys);

  const navRef = useRef(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    // Check for UTM parameters. If there are any, clear any existing UTM parameters and replace with new ones
    let hasParams = false;
    for (const param of utmParams) {
      if (params.has(param)) {
        hasParams = true;
        break;
      }
    }

    if (hasParams) {
      for (const param of utmParams) {
        if (params.has(param)) {
          setCookie(param, params.get(param));
        } else {
          removeCookie(param);
        }
      }
    }

  }, []);

  useLayoutEffect(() => {
    let scrollFn = () => { };
    let ticking = false;
    let pos = 0;

    const setTheStuck = () => {
      setStuck(pos > 18);
    }

    if (typeof window !== 'undefined') {
      setStuck(window.scrollY > 18)

      scrollFn = () => {
        pos = window.scrollY;

        if (!ticking) {
          window.requestAnimationFrame(() => {
            setTheStuck();
            ticking = false;
          });

          ticking = true;
        }
      };


      window.addEventListener('scroll', scrollFn)
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', scrollFn)
      }
    }

  }, []);

  const mapLinkToComponent = (link) => {
    let pathname = '';
    if (typeof window !== 'undefined') pathname = location.pathname;

    return <CustomLink
      className='hover:no-underline'
      link={{ ...link }} >
      <div className={classNames("nav-link",
        {
          'active': pathname.includes(encodeURI(link.url.replace(`/${locale}`, '')))
        })}>
        {link.text}
      </div>
    </CustomLink>
  }

  return (
    <>
      <a className='skip-to-content-link' href='#main'>
        {getLocalization('SKIP_TO_CONTENT')}
      </a>
      {/* The actual navbar */}
      <nav ref={navRef} className={classNames(`navbar sticky lg:fixed w-full z-20 flex flex-col top-0 bg-white border-primary border-t-4 lg:justify-center`, {
        'h-screen': mobileMenuIsShown,
        'stuck': stuck,
        // 'lg:h-36': !stuck,
        // 'shadow-md lg:h-32': stuck,
      })}>
        <div className="container flex flex-row items-center justify-center lg:justify-between text-lg py-2 lg:py-0 relative">
          {/* Hamburger menu on mobile */}
          {!mobileMenuIsShown &&
            <button onClick={() => setMobileMenuIsShown(true)} className="absolute left-8 block lg:hidden">
              <MdMenu className="h-8 w-auto" />
            </button>
          }

          {/* Close button on mobile */}
          {mobileMenuIsShown &&
            <button onClick={() => setMobileMenuIsShown(false)} className="absolute left-8">
              <MdClose className="h-8 w-auto text-gray" />
            </button>
          }

          {/* Content aligned to the left */}
          <div className="flex flex-row items-center gap-4 xl:gap-10">
            <Link to={localizePath({ ...pageContext, slug: "" })}>
              <Image
                placeholder="none"
                media={navbar.logo}
                className="h-20 lg:h-14 w-auto object-contain py-4 lg:py-0"
              />
            </Link>
            {/* List of left links on desktop */}
            <ul className="hidden list-none lg:flex flex-row gap-4 items-baseline mt-11">
              {navbar.leftlinks.map(navLink => (
                <li key={navLink.id}>
                  {mapLinkToComponent(navLink)}
                </li>
              ))}
            </ul>
          </div>

          <div className="flex items-center mt-11 gap-4">
            {/* CTA button on desktop */}
            {navbar.button && (
              <div className="hidden lg:block">
                <ButtonLink
                  linkClassName='hover:no-underline'
                  button={navbar.button}
                  appearance={getButtonAppearance(navbar.button.type, "light")}
                  compact
                />
              </div>
            )}
            {/* List of right links on desktop */}
            <ul className="hidden list-none lg:flex flex-row gap-4 items-center">
              {navbar.rightlinks.map(navLink => (
                <li key={navLink.id}>
                  {mapLinkToComponent(navLink)}
                </li>
              ))}
            </ul>
            {/* Locale Switch */}
            {pageContext.localizations && (
              <div className={classNames('transition-opacity', {
                'opacity-100 pointer-events-auto': mobileMenuIsShown,
                'pointer-events-none lg:pointer-events-auto opacity-0 lg:opacity-100': !mobileMenuIsShown
              })}>
                <LocaleSwitch pageContext={pageContext} />
              </div>
            )}
          </div>
        </div>

        {/* Mobile navigation menu panel */}
        {mobileMenuIsShown && (
          <MobileNavMenu
            navbar={navbar}
            pageContext={pageContext}
          />
        )}
      </nav>

    </>
  )
}

Navbar.propTypes = {
  navbar: PropTypes.shape({
    logo: mediaPropTypes,
    leftlinks: PropTypes.arrayOf(linkPropTypes),
    rightlinks: PropTypes.arrayOf(linkPropTypes),
    button: buttonLinkPropTypes,
  }),
}

export default Navbar
