const { colors } = require(`tailwindcss/defaultTheme`);

module.exports = {
  purge: ["./src/components/**/*.js", "./src/pages/**/*.js", "./src/forms/**/*.js"],
  variants: {
    extend: {
      opacity: ['disabled'],
      cursor: ['disabled'],
      backgroundColor: ['disabled'],
      borderColor: ['disabled'],
    }
  },
  theme: {
    extend: {
      colors: {
        primary: {
          DEFAULT: 'var(--primary-color)',
          light: '#d1de9f',
          buttonHovered: '#87ae37',
        },
        secondary: {
          DEFAULT: '#7d8790',
          buttonHovered: '#616970',
        },
        gray: {
          darkest: '#2e3235',
          dark: '#6a737a',
          DEFAULT: '#7d8790',
          light: '#9ba3a9',
          lighter: '#cdd1d4',
          lightest: '#f7f7f7',
        }
      },
      container: {
        center: true,
        padding: {
          DEFAULT: "2rem",
          md: "3rem",
          lg: "4rem",
        },
      },
      lineHeight: {
        'extra-tight': '1.15',
      },
      spacing: {
        '1.25': '.3125rem',
        '18': '4.5rem',
        '108': '27rem',
        '112': '28rem',
        '120': '30rem',
        '128': '32rem',
        '132': '34rem',
        '136': '36rem',
        '140': '38rem',
        '144': '40rem',
        '256': '64rem',
        '280': '70rem',
      },
      transitionProperty: {
        'height': 'height, max-height'
      }
    },
    screens: {
      sm: "640px",
      md: "768px",
      lg: "1024px",
      xl: "1366px",
      '2xl': "1800px",
    },
  },
  plugins: [require("@tailwindcss/typography"), require('@tailwindcss/forms')],
};
