import React from 'react';
import ReactMarkdown from "react-markdown";

export default (props) => {
    const { components } = props;
    return (
        <ReactMarkdown parserOptions={{ commonmark: true }} components={{
            p: ({ node, ...props }) => (
                <p className='mb-4' {...props}>{props.children}</p>
            ),
            a: ({node, ...props}) => (
                <a href={props.href.replace('/en', '')}>{props.children}</a>
            ),
            ...components
        }} {...props}>
            {props.children}
        </ReactMarkdown>
    )
}