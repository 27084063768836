import React, { useEffect, useCallback, useState, useRef } from "react"
import { navigate } from "gatsby-link"
import { Link } from "gatsby"
import WorldIcon from "@/components/icons/world"
import { useCookies } from "react-cookie"
import Tooltip from "@tippyjs/react"
import tailwindConfig from "../../tailwind.config"
import resolveConfig from "tailwindcss/resolveConfig"
import { useMedia } from "../utils/hooks"

// const localeMap = {
//   'en': 'English',
//   'es': 'Spanish - Latin America , Español - Latinoamérica',
//   'fr': 'French , Français',
//   'de': 'German, Deutsche',
//   'pt': 'Portuguese - Brazilian , Português - Brasileiro',
//   'zh': 'Chinese - Simplified , 简体中文',
//   'th': 'Thai , ไทย',
// }
const localeMap = {
  en: "English",
  es: "Español",
  fr: "Français",
  de: "Deutsche",
  pt: "Português",
  zh: "简体中文",
  th: "ไทย",
}

const LocaleSwitch = ({ footer = false, pageContext }) => {
  const isMounted = useRef(false)
  const select = useRef()
  const [cookies, setCookie] = useCookies()
  const [locale, setLocale] = useState(
    cookies.GATSBY_LOCALE || pageContext.locale
  )
  const [localizedPaths, setLocalizedPaths] = useState(null)

  const handleLocaleChange = async selectedLocale => {
    setCookie("GATSBY_LOCALE", selectedLocale, {
      path: "/",
      secure: process.env.NODE_ENV,
      sameSite: "Strict",
    })
    setLocale(selectedLocale)
  }

  let tooltipPlacement = useMedia(
    [
      `(min-width: ${resolveConfig(tailwindConfig).theme.screens.md})`,
      `(max-width: ${resolveConfig(tailwindConfig).theme.screens.md})`,
    ],
    ["top-end", "bottom-end"],
    ["top-end"]
  )

  const renderLocales = useCallback(() => {
    return Object.keys(localeMap).map(localeKey => {
      return (
        <p className="capitalize hover:text-primary mr-12 ml-8 my-1 h-full cursor-pointer p-1 rounded-md text-center">
          <Link
            to={localeKey === "en" ? "/" : `/${localeKey}`}
            key={localeKey}
            onClick={() => handleLocaleChange(localeKey)}
            role="option"
          >
            {localeMap[localeKey]}
          </Link>
        </p>
      )
    })
  }, [localizedPaths])

  const renderFooter = () => {
    return (
      <Tooltip
        trigger="click"
        className={`absolute w-max bg-white p-2 pb-12 lg:pt-2 lg:pb-8 bottom-0 lg:-bottom-12 right-0 lg:-right-4 shadow-lg rounded-md text-lg`}
        placement={"top-end"}
        animation="fade"
        duration={500}
        interactive
        interactiveBorder={20}
        content={renderLocales()}
      >
        <div
          ref={select}
          className="fixed lg:relative ml-4"
          style={{ zIndex: 10000 }}
        >
          <button
            style={{ zIndex: 10000 }}
            className="flex items-center justify-between px-2 py-2 cursor-pointer rounded-md lg:right-auto relative"
          >
            <WorldIcon className="bg-transparent text-white" />
          </button>
        </div>
      </Tooltip>
    )
  }

  if (footer) return renderFooter()

  return (
    <Tooltip
      trigger="click"
      className={`-top-16 md:top-4 lg:top-0 lg:bottom-auto right-0 lg:-right-4 absolute w-max bg-white p-2 pb-2 pt-8 mt-1 shadow-lg rounded-md text-lg`}
      placement={tooltipPlacement}
      animation="fade"
      duration={500}
      interactive
      interactiveBorder={20}
      content={renderLocales()}
    >
      {/* Icon */}
      <div
        ref={select}
        className="bottom-4 lg:bottom-auto right-4 lg:right-auto absolute lg:relative"
        style={{ zIndex: 10000 }}
      >
        <button
          style={{ zIndex: 10000 }}
          className="flex items-center justify-between px-2 py-2 cursor-pointer rounded-md lg:right-auto relative"
        >
          <WorldIcon className="bg-white text-primary" />
        </button>
      </div>
    </Tooltip>
  )
}

export default LocaleSwitch
