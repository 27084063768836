import { fetchAPI } from "./api"

export function getLocalizationKey(localizationKeys, key) {
  key = key.toUpperCase();
  if (localizationKeys[key]) return localizationKeys[key];
  return key;
}

export function localizePath(page) {
  const { locale, defaultLocale, slug, isPreview } = page
  if (isPreview) {
    // The preview requires a prefix
    return `/${locale}/preview/${slug}`
  }

  if (locale === defaultLocale) {
    // The default locale is not prefixed
    return `/${slug}`
  }

  // The slug should have a localePrefix
  return `/${locale}/${slug}`
}

