import React from "react"

const WorldIcon = ({ className }) => {
  return (
    <div className={`w-7 h-7 mt-1 fill-current stroke-current ${className}`}>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 85.1 82.6" style={{ enableBackground: 'new 0 0 85.1 82.6' }}>
        <g>
          <polygon points="18.9,32.2 24.7,32.2 21.8,22.7 	" />
          <path d="M29.8,3.2h-20c-4.4,0-8,3.6-8,8v68l24-24h18L29.8,3.2z M26,36.2h-8.3l-1.9,6h-4l8-26h4l8,26h-4L26,36.2z" />
          <path d="M73.8,3.2h-40l14,52h26c4.4,0,8-3.6,8-8v-36C81.8,6.8,78.2,3.2,73.8,3.2z M59.8,16.2h4v4h-4V16.2z M71.8,42.2
		c-3.7,0-7.3-0.7-10.6-2.1c-2.9,1.4-6.1,2.1-9.4,2.1v-4c1.7,0,3.4-0.2,5-0.7c-3.1-2.5-5-5.7-5-9.3h4c0,2.9,2.1,5.5,5.3,7.4
		c4-2.4,6.6-6.7,6.7-11.4h-16v-4h20v4l0,0c-0.1,5.1-2.4,9.9-6.4,13.1c2.1,0.6,4.2,0.9,6.4,0.9V42.2z"/>
        </g>
      </svg>
    </div >
  )
}

export default WorldIcon
